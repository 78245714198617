import * as React from 'react'
import Layout from "../../components/layout";
import {graphql} from "gatsby";
import {MDXRenderer} from "gatsby-plugin-mdx";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import Gitalk from "gatsby-plugin-gitalk";
import '@suziwen/gitalk/dist/gitalk.css'
import {MDXProvider} from "@mdx-js/react";
import CodeBlock from "../../components/CodeBlock";
import Seo from "../../components/Seo"

export const query = graphql`
    query post($id: String) {
        mdx(id: {eq: $id}) {
            frontmatter {
                title
                hero_image_alt
                hero_image_credit_link
                hero_image_credit_text
                date(formatString: "LL", locale:"fr")
                hero_image_show
                hero_image {
                    childImageSharp {
                        gatsbyImageData(width: 1000)
                    }
                    extension
                    publicURL
                }
                embeddedImagesLocal {
                    childImageSharp {
                        gatsbyImageData(width: 1000)
                        id
                    }
                    publicURL
                }
            }
            slug
            id
            body
        }
    }
`

const BlogPost = ({data}) => {
    let gitalkConfig = {
        id: data.mdx.slug || data.mdx.id,
        title: data.mdx.frontmatter.title,
    }
    let baliseimage
    if (data.mdx.frontmatter.hero_image.extension !== "svg") {
        const image = getImage(data.mdx.frontmatter.hero_image)
        baliseimage = <GatsbyImage
            image={image}
            alt={data.mdx.frontmatter.hero_image_alt}
        />
    } else {
        baliseimage = <img src={data.mdx.frontmatter.hero_image.publicURL} className="w-full" alt={data.mdx.frontmatter.hero_image_alt} />
    }
    return (
        <Layout pageTitle={data.mdx.frontmatter.title}>
            <Seo title={data.mdx.frontmatter.title} />
            <div className="max-w-screen-md mx-auto">
                <div className="font-bold text-4xl mt-4">{data.mdx.frontmatter.title}</div>
                <p>{data.mdx.frontmatter.date}</p>
                { data.mdx.frontmatter.hero_image_show === "true" && <div className="relative mb-12">
                    {baliseimage}
                    {data.mdx.frontmatter.hero_image_credit_text &&
                        <div className="flex items-center justify-end w-full h-6 bg-black/80 absolute -bottom-5 left-0">
                            <div className="text-white mr-2">
                                crédits photo : {" "}
                                <a className="no-underline text-white hover:text-gray-200 hover:underline"
                                   href={data.mdx.frontmatter.hero_image_credit_link}>
                                    {data.mdx.frontmatter.hero_image_credit_text}
                                </a>
                            </div>
                        </div>
                    }
                </div> }
                <MDXProvider components={{
                    pre: props => <div {...props}/>,
                    code: CodeBlock
                }}>
                    <MDXRenderer frontmatter={data.mdx.frontmatter}>
                        {data.mdx.body}
                    </MDXRenderer>
                </MDXProvider>

                <Gitalk options={gitalkConfig}/>
            </div>
        </Layout>
    )
}
export default BlogPost
